import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Select, Checkbox } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// import AlertImageContainer from '../ConfigureAlertBanner/AlertImageContainer';
import TextEditor from './TextEditor';
import launchGroups from '../../constants/defaultSettingVariationConstants';
import {
  saveProductUpdatePost as saveProductUpdatePostAction,
  deleteProductUpdatePost as deleteProductUpdatePostAction,
} from '../../actions/productUpdates';
import classes from './ProductRelease.module.scss';
import FullPageLoading from '../../component/FullPageLoading';
import { productUpdatePostPropType } from '../../reducers/productUpdates';
import { popupAlert as popupAlertAction } from '../../actions/alert';
import { moduleOptions, subModuleOptions } from '../AutomatedMarketing/utils';
import { fetchClusterHubs as fetchClusterHubsAction } from '../../actions/clusterHubs';

const { Option } = Select;

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_CTA_LENGTH = 50;

class ProductUpdateForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      image: '',
      ctaLink: '',
      ctaText: '',
      ctaOpenInSameWindow: false,
      selectedHubs: [],
      selectedLaunchGroups: [],
      descriptionLength: 0,
      requireModules: [],
      excludeModules: [],
    };
  }

  componentDidMount = () => {
    const { fetchClusterHubs } = this.props;
    fetchClusterHubs();
  };

  componentDidUpdate(prevProps) {
    const { match, productUpdates } = this.props;
    const editingPostId = get(match, 'params.id', undefined);
    const prevEditingPostId = get(prevProps.match, 'params.id', undefined);
    const posts = get(productUpdates, 'posts');
    const prevPosts = get(prevProps, 'productUpdates.posts');
    if (editingPostId && posts !== prevPosts && !isEmpty(posts)) {
      this.copyPostsValueForEditing(posts[editingPostId]);
    }

    if (editingPostId !== prevEditingPostId && !editingPostId) {
      this.resetFields();
    }
  }

  resetFields = () => {
    this.setState({
      title: '',
      description: '',
      image: '',
      ctaLink: '',
      kbLink: '',
      ctaText: '',
      ctaOpenInSameWindow: false,
      selectedHubs: [],
      selectedLaunchGroups: [],
      requireModules: [],
      excludeModules: [],
    });
  };

  copyPostsValueForEditing = post => {
    const obj = {
      title: post.title,
      description: post.description,
      image: post.image,
      ctaLink: post.cta.link,
      kbLink: post.cta.kbLink,
      ctaText: post.cta.text,
      ctaOpenInSameWindow: post.cta.openInSameWindow,
      selectedHubs: post.objectId || [],
      selectedLaunchGroups: post.launchGroup || [],
      requireModules: post.requireModules || [],
      excludeModules: post.excludeModules || [],
    };

    this.setState(obj);
  };

  handleInputChange = (key, e) => {
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  handleDescriptionChange = (description, delta, source, editor) => {
    this.setState({
      description,
      descriptionLength: editor.getText().trim().length,
    });
  };

  handleSelectInputChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleHubChange = newSelectedHubs => {
    if (newSelectedHubs.indexOf('ALL') > -1) {
      return this.setState({ selectedHubs: ['ALL'] });
    }
    return this.setState({ selectedHubs: newSelectedHubs });
  };

  handleLaunchGroupChange = newSelectedLGs => {
    if (newSelectedLGs.indexOf('ALL') > -1) {
      return this.setState({ selectedLaunchGroups: ['ALL'] });
    }
    return this.setState({ selectedLaunchGroups: newSelectedLGs });
  };

  handleSubmit = () => {
    const {
      title,
      description,
      ctaText,
      ctaLink,
      kbLink,
      ctaOpenInSameWindow,
      selectedHubs,
      selectedLaunchGroups,
      image,
      descriptionLength,
      requireModules,
      excludeModules,
    } = this.state;
    const { saveProductUpdatePost, match, popupAlert } = this.props;

    let error = false;

    if (title.length > MAX_TITLE_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Title cannot be more than ${MAX_TITLE_LENGTH} characters`,
      });
      error = true;
    }

    if (ctaText.length > MAX_CTA_LENGTH) {
      if (!error) {
        popupAlert({
          type: 'error',
          message: `Banner CTA cannot be more than ${MAX_CTA_LENGTH} characters`,
        });
        error = true;
      }
    }

    // if ctaLink is true then ctaText should also be true and vice versa
    if (
      (ctaText.length > 0 || ctaLink.length > 0) &&
      (ctaText.length == 0 || ctaLink.length == 0)
    ) {
      if (!error) {
        popupAlert({
          type: 'error',
          message: `CTA link is required with CTA text and vice versa`,
        });
        error = true;
      }
    }

    // only validate if the ctaLink is true
    if (ctaLink) {
      const expression = /(https?):\/\/[^ "]+\.+(\w)*(\/.*)?$/gi;
      const regex = new RegExp(expression);
      if (!ctaLink.match(regex) && !error) {
        popupAlert({
          type: 'error',
          message: `CTA link must be a valid URL.`,
        });
        error = true;
      }
    }

    // only validate if the kbLink is true
    if (kbLink) {
      const expression = /(https?):\/\/[^ "]+\.+(\w)*(\/.*)?$/gi;
      const regex = new RegExp(expression);
      if (!kbLink.match(regex) && !error) {
        popupAlert({
          type: 'error',
          message: `Knowledge Board link must be a valid URL.`,
        });
        error = true;
      }
    }

    if (error) {
      return;
    }

    const obj = {
      title,
      description,
      image,
      objectType: 'Hub',
      objectId: selectedHubs.indexOf('ALL') > -1 ? null : selectedHubs,
      launchGroup: selectedLaunchGroups,
      cta: {
        text: ctaText,
        link: ctaLink,
        kbLink,
        openInSameWindow: ctaOpenInSameWindow,
      },
      requireModules,
      excludeModules,
    };
    if (match.params && match.params.id) {
      obj.id = match.params.id;
    }
    saveProductUpdatePost(obj);
  };

  handleDelete = id => () => {
    const { deleteProductUpdatePost, history } = this.props;
    deleteProductUpdatePost({ id });
    history.push('/product-releases');
  };

  handleCheckboxChange = e => {
    this.setState({ ctaOpenInSameWindow: e.target.checked });
  };

  render() {
    const { hubs, loading, match, productUpdates } = this.props;

    const {
      title,
      description,
      image,
      ctaLink,
      kbLink,
      ctaText,
      ctaOpenInSameWindow,
      selectedHubs,
      selectedLaunchGroups,
      descriptionLength,
      requireModules,
      excludeModules,
    } = this.state;

    const filterOption = (input, option) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    // const shouldShowCTALinkError = this.shouldShowCTALinkError(hubBannerCtaLink);
    const disableSelectHubOptions = selectedHubs.indexOf('ALL') > -1;
    const disableSelectLaunchGroupOptions =
      selectedLaunchGroups.indexOf('ALL') > -1;
    return (
      <div className={`ck-card ${classes['product-release-container']}`}>
        {(loading || productUpdates.loadingPosts) && (
          <FullPageLoading fontSize={30} />
        )}
        {!loading && hubs.length > 0 && (
          <>
            <h3>Product Release Posts Configuration</h3>
            <Form>
              <Form.Item
                label={`Title (${title.length} / ${MAX_TITLE_LENGTH})`}
                required
                className={classes['ant-form-item']}
              >
                <Input
                  value={title}
                  onChange={e => this.handleInputChange('title', e)}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                required
                className={classes['ant-form-item']}
              >
                <TextEditor
                  style={classes['quill-editor']}
                  onChange={this.handleDescriptionChange}
                  value={description}
                  getInitialCount={count => {
                    this.setState({ descriptionLength: count });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={`CTA Button Text (${ctaText.length} / ${MAX_CTA_LENGTH})`}
                className={classes['ant-form-item']}
              >
                <Input
                  value={ctaText}
                  onChange={e => this.handleInputChange('ctaText', e)}
                  placeholder="default: Learn more..."
                />
              </Form.Item>
              <Form.Item label="CTA Link" className={classes['ant-form-item']}>
                <Input
                  placeholder="e.g. https://support.peoplegrove.com"
                  value={ctaLink}
                  onChange={e => this.handleInputChange('ctaLink', e)}
                />
                <Checkbox
                  checked={ctaOpenInSameWindow}
                  onChange={this.handleCheckboxChange}
                >
                  Open in same window
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Knowledge Board Link"
                className={classes['ant-form-item']}
              >
                <Input
                  placeholder="e.g. https://support.peoplegrove.com"
                  value={kbLink}
                  onChange={e => this.handleInputChange('kbLink', e)}
                />
              </Form.Item>
              <Form.Item label="Require Module">
                <Select
                  id="module"
                  placeholder="Select module(s)"
                  mode="multiple"
                  value={requireModules}
                  onChange={e =>
                    this.handleSelectInputChange('requireModules', e)
                  }
                  getPopupContainer={node => node.parentNode}
                  allowClear
                  style={{ width: '100%' }}
                >
                  {[...subModuleOptions, ...moduleOptions].map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Exclude Module">
                <Select
                  id="module"
                  placeholder="Select module(s)"
                  mode="multiple"
                  value={excludeModules}
                  onChange={e =>
                    this.handleSelectInputChange('excludeModules', e)
                  }
                  getPopupContainer={node => node.parentNode}
                  allowClear
                  style={{ width: '100%' }}
                >
                  {[...subModuleOptions, ...moduleOptions].map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Select Hub">
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select a hub"
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  onChange={this.handleHubChange}
                  value={selectedHubs}
                  filterOption={filterOption}
                >
                  <Option value="ALL">Select All</Option>
                  {hubs.map(hub => (
                    <Option
                      value={hub.id}
                      key={hub.id}
                      disabled={disableSelectHubOptions}
                    >
                      {hub.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Select Launch Group">
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select a Launch Group"
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  onChange={this.handleLaunchGroupChange}
                  value={selectedLaunchGroups}
                  filterOption={filterOption}
                >
                  <Option value="ALL">Select All</Option>
                  {launchGroups.map(lg => (
                    <Option
                      value={lg.key}
                      key={lg.name}
                      disabled={disableSelectLaunchGroupOptions}
                    >
                      {lg.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item label="Image" className={classes['ant-form-item']}>
                <AlertImageContainer
                  hubBannerImage={image}
                  handleImageChange={value => this.setState({ image: value })}
                />
              </Form.Item> */}
              <Form.Item
                className={`${classes['ant-form-item']} ${classes['submit-btn']}`}
              >
                {match.params && match.params.id && (
                  <Button
                    type="danger"
                    onClick={this.handleDelete(parseInt(match.params.id, 10))}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="primary"
                  className={classes['primary-btn']}
                  disabled={!title || !descriptionLength > 0}
                  onClick={this.handleSubmit}
                >
                  {match.params && match.params.id ? 'Update' : 'Submit'}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    );
  }
}

ProductUpdateForm.propTypes = {
  hubs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  saveProductUpdatePost: PropTypes.func.isRequired,
  deleteProductUpdatePost: PropTypes.func.isRequired,
  productUpdates: PropTypes.shape({
    loadingPosts: PropTypes.bool.isRequired,
    posts: PropTypes.objectOf(productUpdatePostPropType),
  }).isRequired,
  popupAlert: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productUpdates, clusterHubs }) => {
  const { loading, hubs } = clusterHubs;
  return {
    loading,
    hubs,
    productUpdates,
  };
};

const mapDispatchToProps = {
  saveProductUpdatePost: saveProductUpdatePostAction,
  deleteProductUpdatePost: deleteProductUpdatePostAction,
  popupAlert: popupAlertAction,
  fetchClusterHubs: fetchClusterHubsAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductUpdateForm)
);
